<template>

<!--  <div class="courseDescription">-->
<!--    <div class="title">立即报名，立即开始！自定义开课时间，报名即学</div>-->
<!--    <div class="subtitle">前置课程《突击班》高频200题，报名就看，马上刷题</div>-->
<!--    <div style="width: 1200px; margin: 0 auto">-->
<!--      <el-row style="margin-top: 40px">-->
<!--        <el-col :span="12">-->
<!--          <img-->
<!--            class="classimgs"-->
<!--            src="@/assets/images/mokuai/class2.png"-->
<!--            alt=""-->
<!--          />-->
<!--        </el-col>-->
<!--        <el-col :span="12">-->
<!--          <div class="description">-->
<!--            <div class="destitle">独立课程</div>-->
<!--            <p>规划结束后复习，或者开班前学习，自定义随时开课</p>-->
<!--            <p class="orangep">-->
<!--              自定义开课时间<br />-->
<!--              不占用课程规划有效期，不开课永久保留<br />-->
<!--              可以提前开始，也可以课程规划后复习-->
<!--            </p>-->
<!--            <p class="redp">-->
<!--              赠送《突击班》高频200题<br />-->
<!--              不占用课程规划有效期，不开课永久保留<br />-->
<!--              可以提前开始，也可以课程规划后复习-->
<!--            </p>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--      <el-row style="margin-top: 40px">-->
<!--        <el-col :span="12">-->
<!--          <div class="description">-->
<!--            <div class="destitle">课程规划</div>-->
<!--            <p>正式开班时才能开始，统一进度统一刷题</p>-->
<!--            <p class="orangep">-->
<!--              定时开课<br />-->
<!--              正式开班时开始，小伙伴统一进度刷题<br />-->
<!--              答疑群一起讨论一起刷题，每周打卡一周50题-->
<!--            </p>-->
<!--            <p class="redp">-->
<!--              班课规划内容 = 所有独立课程内容合集<br />-->
<!--              把《课程规划》拆分颗粒化，是独立课程的合集，<br />-->
<!--              包含所有独立课程内容（LC除外），想看哪部分就看哪部分-->
<!--            </p>-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="12">-->
<!--          <img-->
<!--            class="classimgs"-->
<!--            src="@/assets/images/mokuai/class1.png"-->
<!--            alt=""-->
<!--          />-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </div>-->
<!--  </div>-->
</template>
<script>
export default {
  name: "courseDescription",
};
</script>
<style scoped>
.courseDescription {
  padding: 30px 0;
  text-align: center;
}
.title {
  font-size: 32px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 48px;
}
.subtitle {
  margin-top: 12px;
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 24px;
}
.classimgs {
  width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 8%);
}
.description {
  text-align: left;
  margin: 0 auto;
  width: 400px;
  margin-top: 40px;
}
.destitle {
  font-size: 20px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin-bottom: 15px;
  display: inline-block;
  border-bottom: 3px solid rgba(116, 174, 255, 1);
}
.description p {
  position: relative;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 28px;
  margin-bottom: 20px;
}
.description p.redp::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: rgba(222, 19, 6, 1);
  border-radius: 50%;
  left: -15px;
  top: 10px;
}
.description p.orangep::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: rgba(254, 172, 74, 1);
  border-radius: 50%;
  left: -15px;
  top: 10px;
}
</style>